

export default {
    name: "ClockIn",
    props: {
    },
    created() {

        let t = this;

        if (!window.google || !window.google.maps) {
            let src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBmM62bnkKj7SvAsQ5X8VmsY9AZ0gAsA38&libraries=geometry,marker';
            let s = document.createElement('script');
            s.src = src;

            document.body.appendChild(s);
        }

        window.tryLocate = (fn) => {
            if (window.google && window.google.maps) {

                clearInterval(window.googleMapInterval);

                //KYORAN经纬度
                const targetLatLng = { lat: 34.992531344580755, lng: 135.76254639761083 }; // 替换为目标位置的经纬度（例如纽约）

                function checkProximity() {
                    // 检查浏览器是否支持地理定位
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(showPosition, (err) => {
                            A.err(t.t('お使いのデバイスはGPS位置情報をサポートしていません。ブラウザの設定やシステムの権限をオンにしてから、ページを更新して再試行してください。'));
                        });
                    } else {
                        A.err(t.t('お使いのデバイスはGPS位置情報をサポートしていません。ブラウザの設定やシステムの権限をオンにしてから、ページを更新して再試行してください。'));
                    }
                }

                function showPosition(position) {

                    const currentLatLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

                    t.f.lat = position.coords.latitude;
                    t.f.lng = position.coords.longitude;

                    // const currentLatLng = new google.maps.LatLng(34.9883496286263, 135.7610981264951);

                    const targetLocation = new google.maps.LatLng(targetLatLng.lat, targetLatLng.lng);

                    // 计算距离（以米为单位）
                    const distance = google.maps.geometry.spherical.computeDistanceBetween(currentLatLng, targetLocation);

                    t.distance = distance;

                    t.$forceUpdate();

                    // 显示地图和标记
                    const mapOptions = {
                        center: targetLocation,
                        zoom: 14,
                        mapId: 'beeae9f3e6e5dbbf',
                        mapTypeControl: false,      // 禁用地图类型控件
                        scaleControl: false,        // 禁用比例尺控件
                        streetViewControl: false,   // 禁用街景控件
                        rotateControl: false,       // 禁用旋转控件
                        fullscreenControl: false,   // 禁用全屏控件
                    };
                    const map = new google.maps.Map(document.getElementById("map"), mapOptions);

                    let i = document.createElement('img');
                    i.src = '/assets/img/logo-square.png';
                    i.style = "width: 60px; height: 60px;position:relative;top:30px;left:0px;";
                    // 添加目标位置标记
                    new google.maps.marker.AdvancedMarkerElement({
                        position: targetLocation,
                        map: map,
                        title: "KYORAN",
                        content: i,
                    });

                    //   // 绘制5公里范围的红色半透明圆圈
                    new google.maps.Circle({
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.6,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.2,
                        map: map,
                        center: targetLocation,
                        radius: 1000, // 圆圈半径5公里
                    });

                    i = document.createElement('img');
                    i.src = '/assets/img/location.png';
                    i.style = "width: 40px; height: 40px;position:relative;top:0px;left:0px;";

                    new google.maps.marker.AdvancedMarkerElement({
                        position: currentLatLng,
                        map: map,
                        title: "MY LOCATION",
                        content: i,
                    });

                    if (fn)
                        fn();
                }

                checkProximity();
            }
        }

        window.googleMapInterval = setInterval(() => {
            window.tryLocate();
        }, 50);

        t.loadData();
    },
    activated() {


    },
    methods: {
        loadData() {
            let t = this;

            axios.post('clockIn/getToday').then(res => {
                if (res.data.status == 1) {
                    let items = res.data.options.items;

                    if (items.length > 0)
                        t.clockIn_start = items[0].time;

                    if (items.length > 1)
                        t.clockIn_end = items[1].time;

                    t.ready = true;

                }
            });
        },

        clockIn() {

            let t = this;

            if (t.busying)
                return;

            if (t.distance == -1) {
                A.err(t.t('GPSの位置情報を取得した後で出勤打刻を行ってください。')).then(res => {
                    window.tryLocate(() => {
                        t.clockIn();
                    });
                });

                return;
            }

            if (t.distance > 1000) {
                A.err(t.t('現在の位置がKYORANから離れています。赤い円の範囲内（1キロメートル）に入ってから再試行してください。'));
                return;
            }

            t.busying = true;

            axios.post('clockIn/clockInByPos', t.f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    if (!t.clockIn_start)
                        t.clockIn_start = res.data.options.item.time;
                    else if (!t.clockIn_end)
                        t.clockIn_end = res.data.options.item.time;

                    document.querySelector('.loader').style.zIndex = 300;
                    document.querySelector('.loader .loader__result').classList.toggle('loader__result--is-complete');

                }
            });
        }
    },
    data() {
        return {
            ready: false,
            busying: false,
            clockIn_start: null,
            clockIn_end: null,
            distance: -1,
            f: {
                lat: '',
                lng: ''
            }
        };
    },
};

