import { render, staticRenderFns } from "./a4form.vue?vue&type=template&id=89ee2708&"
import script from "./a4form.vue?vue&type=script&lang=js&"
export * from "./a4form.vue?vue&type=script&lang=js&"
import style0 from "./a4form.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports