

export default {
  name: "T",
  mounted() { },
  created() {
    let v = this.$slots.default[0].text;
    if(!window.texts){
      window.texts = {};
    }
    window.texts[v] = '';
    if (window._i18n[window._language][v])
      this.text = window._i18n[window._language][v];
    else
      this.text = v;
  },
  activated: function (el) {
  },
  deactivated() {
  },
  methods: {
  },
  data() {
    return {
      text: ''
    };
  },
};
