import { render, staticRenderFns } from "./business_form.vue?vue&type=template&id=c2b4c884&scoped=true&"
import script from "./business_form.vue?vue&type=script&lang=js&"
export * from "./business_form.vue?vue&type=script&lang=js&"
import style0 from "./business_form.vue?vue&type=style&index=0&id=c2b4c884&prod&lang=css&"
import style1 from "./business_form.css?vue&type=style&index=1&id=c2b4c884&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2b4c884",
  null
  
)

export default component.exports