

export default {
    name: "WorklyReport",
    props: {
    },
    created() {

        let t = this;


        t.loadData();

    },
    activated() {

        let t = this;

    },
    methods: {

        loadData() {
            let t = this;

            t.checkReportByDate();
        },

        checkReportByDate() {

            let t = this;
            let date = t.e.date;

            if (date)
                axios.get('https://hk.kyoran.jp/rp/v1.php?_a=checkReport&date=' + date + '&employee_id=' + t.U.id).then(res => {
                    if (res.data.status == 1) {
                        t.editing = res.data.options.daily_report == null;
                        if (t.editing) {
                            t.e.items = [];
                            for (var i = 0; i < 4; i++) {
                                t.e.items.push({
                                    start_time: '',
                                    end_time: '',
                                    content: '',
                                    showTimePopover: false,
                                });
                            }

                        }
                    }
                });

        },

        selectDate(date) {
            this.e.date = date.format('yyyy-MM-dd');
            this.showDatePopover = false;
            this.checkReportByDate();
        },

        removeItem(item) {
            let t = this;

            if (item.content.trim().length > 0 || item.start_time)
                A.safety('入力済みの内容、削除してもよろしいですか？').then(res => {
                    if (res.isConfirmed) {
                        t.e.items.remove(item);
                    }
                });
            else
                t.e.items.remove(item);

        },
        addItem() {
            this.e.items.push({
                start_time: '',
                content: '',
                showTimePopover: false,
            });
        },

        submitReport() {

            let t = this;

            let f = {};

            f.employee_id = t.U.id;
            f.date = t.e.date;
            f.start_time = t.e.start_time;
            f.end_time = t.e.end_time;
            f.reports = [];

            if (!f.start_time) {
                A.err('出勤時間を選択してください');
                return;
            }

            if (!f.start_time) {
                A.err('退勤時間を選択してください');
                return;
            }

            // 将时间字符串转换为分钟数
            function timeToMinutes(timeStr) {
                var parts = timeStr.split(':');
                var hours = parseInt(parts[0], 10);
                var minutes = parseInt(parts[1], 10);
                return hours * 60 + minutes;
            }

            let startTime = timeToMinutes(f.start_time);
            let endTime = timeToMinutes(f.end_time);

            // 如果结束时间小于或等于开始时间，则结束时间为次日
            if (endTime < startTime) {
                endTime += 24 * 60;
            }

            let msg = '';
            let wrongTime = '';

            t.e.items.forEach(item => {
                if (item.start_time && item.content) {
                    f.reports.push({
                        start_time: item.start_time,
                        content: item.content
                    });

                    var timeToCheck = item.start_time;
                    var timeToCheckMinutes = timeToMinutes(timeToCheck);

                    if (timeToCheckMinutes < startTime) {
                        timeToCheckMinutes += 24 * 60;
                    }

                    if (timeToCheckMinutes < startTime || timeToCheckMinutes > endTime) {
                        msg = '各業務内容の開始時間を出勤時間の範囲内で入力してください。<br>' + [f.start_time, f.end_time].join(' ~ ') + '<br>';
                        wrongTime = item.start_time;
                    }

                }
            });

            if (wrongTime) {
                A.err(msg + "<br>誤った時間: " + wrongTime);
                return;
            }

            if (f.reports.length == 0) {
                A.err('少なくとも1つの項目を記入してください');
                return;
            }
            function _save() {

                t.busying = true;

                axios.post('https://hk.kyoran.jp/rp/v1.php?_a=submitReport', f).then(res => {
                    t.busying = false;
                    if (res.data.status == 1) {
                        t.checkReportByDate();
                    }
                    else if (res.data.status == 2) {
                        A.err('出勤時間より、前に日報は提出できません。');
                    }
                });
            }

            A.safety('内容が記入済みであることを確認し、提出後に変更できません。').then(res => {
                if (res.isConfirmed) {
                    _save();
                }
            });


        }
    },
    data() {
        return {

            list: {
                report: []
            },

            e: {
                start_time: '',
                end_time: '',
                date: new Date().format('yyyy-MM-dd'),
                items: []
            },

            showDatePopover: false,
            showTimePopover1: false,
            showTimePopover2: false,

            minDate: new Date('2023/10/15'),
            maxDate: new Date(),

            editing: true,

        };
    },
};

