
export default {
  name: "UserBox",
  mounted() {},
  created() {},
  activated: function (el) {},
  deactivated() {},
  methods: {
    logout(){
      localStorage.removeItem('_m');
      location.replace('/#/signin');
    }
  },
  data() {
    return {};
  },
};
