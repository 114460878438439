
export default {
  created() {
    let t = this;

    let y = 2024 - 1930;
    for (let i = 0; i < y; i++) {
      t.list.year.push(i + 1930);
    }
    for (let i = 0; i < 12; i++) {
      t.list.month.push(i + 1);
    }
    for (let i = 0; i < 31; i++) {
      t.list.day.push(i + 1);
    }
    for (let i = 0; i <= 20; i++) {
      t.list.owned.push(i);
    }
    t.list.owned.push("20+");

    if (localStorage["business_form_part_1"]) {
      let _f = JSON.parse(localStorage["business_form_part_1"]);
      mirror(_f, t.f);
    }

    if (localStorage["business_form_part_2"]) {
      let _f = JSON.parse(localStorage["business_form_part_2"]);
      mirror(_f, t.f2);
    }

    if (t.f.id) t.f2.id = t.f.id;

    t.f.bid = localStorage["bid"] || "999";
    if (localStorage["lineProfile"]) {
      let lineProfile = JSON.parse(localStorage["lineProfile"]);
      if (lineProfile) t.f.line_user_id = lineProfile.userId;
    }
  },
  activated() {
    let t = this;
  },
  methods: {
    submit1() {
      let t = this;
      let f = t.f;

      t.errMsg = "";

      if (!f.lastname) {
        t.errMsg = "請填寫姓氏";
        return;
      }
      if (!f.firstname) {
        t.errMsg = "請填寫名稱";
        return;
      }
      if (!f.year) {
        t.errMsg = "請指定出生年";
        return;
      }
      if (!f.month) {
        t.errMsg = "請指定出生月";
        return;
      }
      if (!f.day) {
        t.errMsg = "請指定出生日";
        return;
      }
      if (
        !f.email ||
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,6})$/.test(
          f.email
        ) == false
      ) {
        t.errMsg = "請填寫正確的電郵地址";
        return;
      }
      if (!f.tel_prefix) {
        t.errMsg = "請填寫電話/手機的地區號";
        return;
      }
      if (!f.tel) {
        t.errMsg = "請填寫電話/手機的號碼";
        return;
      }

      if (t.busying) return;

      t.busying = true;

      axios.post("businessForm/savePart1", f).then((res) => {
        t.busying = false;

        if (res.data.status == 1) {
          t.f.id = res.data.options.item.id;
          t.f.status = 2;
          localStorage["business_form_part_1"] = JSON.stringify(t.f);
          t.f2.id = res.data.options.item.id;
        }
      });
    },
    submit2() {
      let t = this;
      let f = t.f2;

      t.errMsg = "";

      if (!f.postcode) {
        t.errMsg = "請填寫郵遞區號";
        return;
      }
      if (!f.city) {
        t.errMsg = "請選擇您所在的市";
        return;
      }
      if (!f.town) {
        t.errMsg = "請選擇您所在的縣";
        return;
      }
      if (!f.job) {
        t.errMsg = "請選擇職業";
        return;
      }
      if (!f.sector) {
        t.errMsg = "請選擇行業分類";
        return;
      }
      if (!f.income_payroll) {
        t.errMsg = "請輸入年收入-薪水";
        return;
      }
      if (!f.income_external) {
        t.errMsg = "請輸入年收入-額外收入";
        return;
      }
      if (!f.capital) {
        t.errMsg = "請輸入資本金";
        return;
      }
      if (!f.owned_building) {
        t.errMsg = "請輸入持有房產-整棟樓";
        return;
      }
      if (!f.owned_house) {
        t.errMsg = "請輸入持有房產-別墅";
        return;
      }
      if (!f.owned_department) {
        t.errMsg = "請輸入持有房產-公寓";
        return;
      }

      if (t.busying) return;

      t.busying = true;

      axios.post("businessForm/savePart2", f).then((res) => {
        t.busying = false;

        if (res.data.status == 1) {
          t.f.status = 3;
          localStorage["business_form_part_1"] = JSON.stringify(t.f);
          localStorage["business_form_part_2"] = JSON.stringify(t.f2);
        }
      });
    },
    updateDay() {
      let t = this;
      let d = 0;
      t.list.day.clear();
      if (t.f.year && t.f.month) {
        if (t.f.month == 2)
          if (Number(t.f.year) % 4 == 0) d = 29;
          else d = 28;
        else if ([1, 3, 5, 7, 8, 10, 12].indexOf(Number(t.f.month))) d = 31;
        else d = 30;

        for (let i = 0; i < d; i++) {
          t.list.day.push(i + 1);
        }
        if (Number(t.f.day) > d) t.f.day = "1";
      }
    },
    checkPostcode(){
      let t = this;

      let code = t.f2.postcode;

      let v = '';
      for (const l1 in t.dictZipCode) {
        for (const l2 in t.dictZipCode[l1]) {
          if(code == t.dictZipCode[l1][l2]){
            v = l1;
          }
        }
      }
      if(v){
        for (const l1 in t.dictTown) {
          for (let i = 0; i < t.dictTown[l1].length; i++) {
            const town = t.dictTown[l1][i];
            if(v == town){
              t.f2.town = town;
              t.f2.city = l1;
            }
          }
        }
      }
      else{
        A.err('無法識別郵遞區號，請手動選擇地區');
      }
    },
    selectJob(v){
      this.f2.job = v;
      this.showJobPicker = false;
    },
    selectSector(v){
      this.f2.sector = v;
      this.showSectorPicker = false;
    },
    selectNum1(v){
      this.f2.owned_building = v;
      this.showNum1Picker = false;
    },
    selectNum2(v){
      this.f2.owned_house = v;
      this.showNum2Picker = false;
    },
    selectNum3(v){
      this.f2.owned_department = v;
      this.showNum3Picker = false;
    },
  },
  data() {
    return {
      showJobPicker:false,
      showSectorPicker:false,
      showNum1Picker:false,
      showNum2Picker:false,
      showNum3Picker:false,
      
      errMsg: "",
      dictTown: {
        北部區域: [ "臺北市", "新北市", "基隆市", "新竹市", "桃園市", "新竹縣", "宜蘭縣"],
        
        中部區域: ["臺中市", "苗栗縣", "彰化縣", "南投縣", "雲林縣"],

        南部區域: ["高雄市", "臺南市", "嘉義市", "嘉義縣", "屏東縣", "澎湖縣"],

        東部區域: ["花蓮縣", "臺東縣"],

        福建省: ["金門縣", "連江縣"],
      },
      list: {
        owned: [],
        year: [],
        month: [],
        day: [],
        city: ["北部區域", "中部區域", "南部區域", "東部區域", "福建省"],
        sector: [
          "農、林、漁、牧業",
          "礦業及土石採取業",
          "製造業",
          "電力及燃氣供應業",
          "用水供應及污染整治業",
          "營建工程業",
          "批發及零售業",
          "運輸及倉儲業",
          "住宿及餐飲業",
          "出版影音及資通訊業",
          "金融及保險業",
          "不動產業",
          "專業、科學及技術服務業",
          "支援服務業",
          "公共行政及國防；強制性社會安全",
          "教育業",
          "醫療保健及社會工作服務業",
          "藝術、娛樂及休閒服務業",
          "其他服務業",
        ],
        job: [
          "公司職員",
          "公司高層、董事",
          "公司創辦人，董事長",
          "個體業者",
          "全職主婦",
          "公務員",
          "職業房東",
          "學生",
          "兼職",
          "其他",
        ],
      },
      // f: {
      //   line_user_id: "",
      //   bid: 0,
      //   status: 1,
      //   firstname: "雷欧",
      //   lastname: "艾克西亚",
      //   firstname_en: "Leo",
      //   lastname_en: "Exia",
      //   year: "1994",
      //   month: "8",
      //   day: "12",
      //   email: "test@qq.com",
      //   tel_prefix: "88",
      //   tel: "19999999",
      // },
      // f2: {
      //   id: 0,
      //   postcode: "100",
      //   city: "北部區域",
      //   town: "臺北市",
      //   address: "测试地址",
      //   job: "公司職員",
      //   sector: "不動產業",
      //   income_payroll: "1000",
      //   income_external: "800",
      //   capital: "20000",
      //   owned_building: "1",
      //   owned_house: "2",
      //   owned_department: "10",
      //   agreed_contact: "1",
      // },
      dictZipCode:{
    "基隆市": {
        "仁愛區": "200",
        "信義區": "201",
        "中正區": "202",
        "中山區": "203",
        "安樂區": "204",
        "暖暖區": "205",
        "七堵區": "206"
    },
    "臺北市": {
        "中正區": "100",
        "大同區": "103",
        "中山區": "104",
        "松山區": "105",
        "大安區": "106",
        "萬華區": "108",
        "信義區": "110",
        "士林區": "111",
        "北投區": "112",
        "內湖區": "114",
        "南港區": "115",
        "文山區": "116"
    },
    "新北市": {
        "萬里區": "207",
        "金山區": "208",
        "板橋區": "220",
        "汐止區": "221",
        "深坑區": "222",
        "石碇區": "223",
        "瑞芳區": "224",
        "平溪區": "226",
        "雙溪區": "227",
        "貢寮區": "228",
        "新店區": "231",
        "坪林區": "232",
        "烏來區": "233",
        "永和區": "234",
        "中和區": "235",
        "土城區": "236",
        "三峽區": "237",
        "樹林區": "238",
        "鶯歌區": "239",
        "三重區": "241",
        "新莊區": "242",
        "泰山區": "243",
        "林口區": "244",
        "蘆洲區": "247",
        "五股區": "248",
        "八里區": "249",
        "淡水區": "251",
        "三芝區": "252",
        "石門區": "253"
    },
    "宜蘭縣": {
        "宜蘭市": "260",
        "頭城鎮": "261",
        "礁溪鄉": "262",
        "壯圍鄉": "263",
        "員山鄉": "264",
        "羅東鎮": "265",
        "三星鄉": "266",
        "大同鄉": "267",
        "五結鄉": "268",
        "冬山鄉": "269",
        "蘇澳鎮": "270",
        "南澳鄉": "272",
        "釣魚臺列嶼": "290"
    },
    "新竹市": {
        "東區": "300",
        "北區": "300",
        "香山區": "300"
    },
    "新竹縣": {
        "竹北市": "302",
        "湖口鄉": "303",
        "新豐鄉": "304",
        "新埔鎮": "305",
        "關西鎮": "306",
        "芎林鄉": "307",
        "寶山鄉": "308",
        "竹東鎮": "310",
        "五峰鄉": "311",
        "橫山鄉": "312",
        "尖石鄉": "313",
        "北埔鄉": "314",
        "峨嵋鄉": "315"
    },
    "桃園市": {
        "中壢區": "320",
        "平鎮區": "324",
        "龍潭區": "325",
        "楊梅區": "326",
        "新屋區": "327",
        "觀音區": "328",
        "桃園區": "330",
        "龜山區": "333",
        "八德區": "334",
        "大溪區": "335",
        "復興區": "336",
        "大園區": "337",
        "蘆竹區": "338"
    },
    "苗栗縣": {
        "竹南鎮": "350",
        "頭份市": "351",
        "三灣鄉": "352",
        "南庄鄉": "353",
        "獅潭鄉": "354",
        "後龍鎮": "356",
        "通霄鎮": "357",
        "苑裡鎮": "358",
        "苗栗市": "360",
        "造橋鄉": "361",
        "頭屋鄉": "362",
        "公館鄉": "363",
        "大湖鄉": "364",
        "泰安鄉": "365",
        "銅鑼鄉": "366",
        "三義鄉": "367",
        "西湖鄉": "368",
        "卓蘭鎮": "369"
    },
    "臺中市": {
        "中區": "400",
        "東區": "401",
        "南區": "402",
        "西區": "403",
        "北區": "404",
        "北屯區": "406",
        "西屯區": "407",
        "南屯區": "408",
        "太平區": "411",
        "大里區": "412",
        "霧峰區": "413",
        "烏日區": "414",
        "豐原區": "420",
        "后里區": "421",
        "石岡區": "422",
        "東勢區": "423",
        "和平區": "424",
        "新社區": "426",
        "潭子區": "427",
        "大雅區": "428",
        "神岡區": "429",
        "大肚區": "432",
        "沙鹿區": "433",
        "龍井區": "434",
        "梧棲區": "435",
        "清水區": "436",
        "大甲區": "437",
        "外埔區": "438",
        "大安區": "439"
    },
    "彰化縣": {
        "彰化市": "500",
        "芬園鄉": "502",
        "花壇鄉": "503",
        "秀水鄉": "504",
        "鹿港鎮": "505",
        "福興鄉": "506",
        "線西鄉": "507",
        "和美鎮": "508",
        "伸港鄉": "509",
        "員林市": "510",
        "社頭鄉": "511",
        "永靖鄉": "512",
        "埔心鄉": "513",
        "溪湖鎮": "514",
        "大村鄉": "515",
        "埔鹽鄉": "516",
        "田中鎮": "520",
        "北斗鎮": "521",
        "田尾鄉": "522",
        "埤頭鄉": "523",
        "溪州鄉": "524",
        "竹塘鄉": "525",
        "二林鎮": "526",
        "大城鄉": "527",
        "芳苑鄉": "528",
        "二水鄉": "530"
    },
    "南投縣": {
        "南投市": "540",
        "中寮鄉": "541",
        "草屯鎮": "542",
        "國姓鄉": "544",
        "埔里鎮": "545",
        "仁愛鄉": "546",
        "名間鄉": "551",
        "集集鎮": "552",
        "水里鄉": "553",
        "魚池鄉": "555",
        "信義鄉": "556",
        "竹山鎮": "557",
        "鹿谷鄉": "558"
    },
    "嘉義市": {
        "東區": "600",
        "西區": "600"
    },
    "嘉義縣": {
        "番路鄉": "602",
        "梅山鄉": "603",
        "竹崎鄉": "604",
        "阿里山": "605",
        "中埔鄉": "606",
        "大埔鄉": "607",
        "水上鄉": "608",
        "鹿草鄉": "611",
        "太保市": "612",
        "朴子市": "613",
        "東石鄉": "614",
        "六腳鄉": "615",
        "新港鄉": "616",
        "民雄鄉": "621",
        "大林鎮": "622",
        "溪口鄉": "623",
        "義竹鄉": "624",
        "布袋鎮": "625"
    },
    "雲林縣": {
        "斗南鎮": "630",
        "大埤鄉": "631",
        "虎尾鎮": "632",
        "土庫鎮": "633",
        "褒忠鄉": "634",
        "東勢鄉": "635",
        "臺西鄉": "636",
        "崙背鄉": "637",
        "麥寮鄉": "638",
        "斗六市": "640",
        "林內鄉": "643",
        "古坑鄉": "646",
        "莿桐鄉": "647",
        "西螺鎮": "648",
        "二崙鄉": "649",
        "北港鎮": "651",
        "水林鄉": "652",
        "口湖鄉": "653",
        "四湖鄉": "654",
        "元長鄉": "655"
    },
    "臺南市": {
        "中西區": "700",
        "東區": "701",
        "南區": "702",
        "北區": "704",
        "安平區": "708",
        "安南區": "709",
        "永康區": "710",
        "歸仁區": "711",
        "新化區": "712",
        "左鎮區": "713",
        "玉井區": "714",
        "楠西區": "715",
        "南化區": "716",
        "仁德區": "717",
        "關廟區": "718",
        "龍崎區": "719",
        "官田區": "720",
        "麻豆區": "721",
        "佳里區": "722",
        "西港區": "723",
        "七股區": "724",
        "將軍區": "725",
        "學甲區": "726",
        "北門區": "727",
        "新營區": "730",
        "後壁區": "731",
        "白河區": "732",
        "東山區": "733",
        "六甲區": "734",
        "下營區": "735",
        "柳營區": "736",
        "鹽水區": "737",
        "善化區": "741",
        "大內區": "742",
        "山上區": "743",
        "新市區": "744",
        "安定區": "745"
    },
    "高雄市": {
        "新興區": "800",
        "前金區": "801",
        "苓雅區": "802",
        "鹽埕區": "803",
        "鼓山區": "804",
        "旗津區": "805",
        "前鎮區": "806",
        "三民區": "807",
        "楠梓區": "811",
        "小港區": "812",
        "左營區": "813",
        "仁武區": "814",
        "大社區": "815",
        "岡山區": "820",
        "路竹區": "821",
        "阿蓮區": "822",
        "田寮鄉": "823",
        "燕巢區": "824",
        "橋頭區": "825",
        "梓官區": "826",
        "彌陀區": "827",
        "永安區": "828",
        "湖內鄉": "829",
        "鳳山區": "830",
        "大寮區": "831",
        "林園區": "832",
        "鳥松區": "833",
        "大樹區": "840",
        "旗山區": "842",
        "美濃區": "843",
        "六龜區": "844",
        "內門區": "845",
        "杉林區": "846",
        "甲仙區": "847",
        "桃源區": "848",
        "那瑪夏區": "849",
        "茂林區": "851",
        "茄萣區": "852"
    },
    "屏東縣": {
        "屏東市": "900",
        "三地門": "901",
        "霧臺鄉": "902",
        "瑪家鄉": "903",
        "九如鄉": "904",
        "里港鄉": "905",
        "高樹鄉": "906",
        "鹽埔鄉": "907",
        "長治鄉": "908",
        "麟洛鄉": "909",
        "竹田鄉": "911",
        "內埔鄉": "912",
        "萬丹鄉": "913",
        "潮州鎮": "920",
        "泰武鄉": "921",
        "來義鄉": "922",
        "萬巒鄉": "923",
        "崁頂鄉": "924",
        "新埤鄉": "925",
        "南州鄉": "926",
        "林邊鄉": "927",
        "東港鎮": "928",
        "琉球鄉": "929",
        "佳冬鄉": "931",
        "新園鄉": "932",
        "枋寮鄉": "940",
        "枋山鄉": "941",
        "春日鄉": "942",
        "獅子鄉": "943",
        "車城鄉": "944",
        "牡丹鄉": "945",
        "恆春鎮": "946",
        "滿州鄉": "947"
    },
    "臺東縣": {
        "臺東市": "950",
        "綠島鄉": "951",
        "蘭嶼鄉": "952",
        "延平鄉": "953",
        "卑南鄉": "954",
        "鹿野鄉": "955",
        "關山鎮": "956",
        "海端鄉": "957",
        "池上鄉": "958",
        "東河鄉": "959",
        "成功鎮": "961",
        "長濱鄉": "962",
        "太麻里鄉": "963",
        "金峰鄉": "964",
        "大武鄉": "965",
        "達仁鄉": "966"
    },
    "花蓮縣": {
        "花蓮市": "970",
        "新城鄉": "971",
        "秀林鄉": "972",
        "吉安鄉": "973",
        "壽豐鄉": "974",
        "鳳林鎮": "975",
        "光復鄉": "976",
        "豐濱鄉": "977",
        "瑞穗鄉": "978",
        "萬榮鄉": "979",
        "玉里鎮": "981",
        "卓溪鄉": "982",
        "富里鄉": "983"
    },
    "金門縣": {
        "金沙鎮": "890",
        "金湖鎮": "891",
        "金寧鄉": "892",
        "金城鎮": "893",
        "烈嶼鄉": "894",
        "烏坵鄉": "896"
    },
    "連江縣": {
        "南竿鄉": "209",
        "北竿鄉": "210",
        "莒光鄉": "211",
        "東引鄉": "212"
    },
    "澎湖縣": {
        "馬公市": "880",
        "西嶼鄉": "881",
        "望安鄉": "882",
        "七美鄉": "883",
        "白沙鄉": "884",
        "湖西鄉": "885"
    },

      },
      f: {
        line_user_id: "",
        bid: 0,
        status: 1,
        firstname: "",
        lastname: "",
        firstname_en: "",
        lastname_en: "",
        year: "",
        month: "",
        day: "",
        email: "",
        tel_prefix: "",
        tel: "",
      },
      f2: {
        id: 0,
        postcode: "",
        city: "",
        town: "",
        address: "",
        job: "",
        sector: "",
        income_payroll: "",
        income_external: "",
        capital: "",
        owned_building: "0",
        owned_house: "0",
        owned_department: "0",
        agreed_contact: "1",
      },
      selectedCity: null,
    };
  },
};
