
import { ImagePreview } from 'vant';
export default {

    created() {

        let t = this;

        window._language = 'ja-jp';

        t.loadData();

    },
    activated() {

        let t = this;

        document.getElementById('realtimeStr').innerText = (new Date()).format('hh:mm:ss');

        setInterval(function () {
            document.getElementById('realtimeStr').innerText = (new Date()).format('hh:mm:ss');
        }, 1000);

    },
    methods: {

        loadData() {
            let t = this;

            let p = getURLParams();

            axios.get('cleanArea?_with=items').then(res => {
                if (res.data.status == 1) {
                    t.list.cleanArea = res.data.options.items;

                    axios.get('cleanTask?_with=room,order,results,roomCleanAreas&task_id=' + p.task_id).then(res => {
                        if (res.data.status == 1) {
                            let arr = res.data.options.items;
                            t.editTaskItem(arr[0]);
                        }
                    });
                }
            });


        },


        editTaskItem(item) {

            let t = this;

            t.currentTask = item;

            t.todayDateStr = (new Date(item.date)).format('yyyy. MM. dd');

            let arr = [];

            let selectedIds = [];
            let dictAreaImgs = {};

            if (t.currentTask.results && t.currentTask.results.length > 0) {
                t.currentTask.results.forEach(res => {
                    if (res.selection) {
                        selectedIds.append(res.selection.split(','));
                    }
                    if (res.img_urls) {
                        dictAreaImgs[res.area_id] = res.img_urls.split(',');
                    }
                });
            }

            let dictRCA = {};

            t.currentTask.room_clean_areas.forEach(rca => {

                dictRCA[rca.area_id] = rca;

            });

            t.list.cleanArea.forEach(area => {

                area.imgs = [];
                area.columns = [];

                area.items.forEach(item => {
                    item.selected = false;

                    if (selectedIds.indexOf(item.id.toString()) > -1)
                        item.selected = true;
                });

                if (dictAreaImgs[area.id]) {
                    area.imgs = dictAreaImgs[area.id];
                }

                if (area.column == 1) {
                    area.columns.push(area.items);
                }
                else if (area.column == 2) {
                    let rows = area.rows.split(',');
                    area.columns.push(area.items.slice(0, Number(rows[0])));
                    area.columns.push(area.items.slice(Number(rows[0]), Number(rows[1]) + Number(rows[0])));
                }

                let rca = dictRCA[area.id];

                if (rca && rca.quantity > 0) {
                    area.quantity = rca.quantity;
                    arr.push(area);
                }

            });

            t.list.roomArea = arr;

        },

        preview(area, idx) {

            let t = this;
            let imgUrls = [];

            if (area.imgs.length > 0) {
                area.imgs.forEach(img => {
                    imgUrls.push(t.url(img));
                });

                ImagePreview({
                    images: imgUrls,
                    startPosition: idx,
                    closeable: true,
                });
            }
        },

        reset() {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('このレポートが返送されることを確認しますか？').then(res => {
                t.busying = false;

                if (res.isConfirmed) {
                    axios.post('cleanTask/reset/' + t.currentTask.id).then(res => {
                        if (res.data.status == 1) {
                            t.currentTask.status = 2;
                            if (window.opener)
                                window.opener.postMessage('success', '*');
                            A.toast('それは送り返された。');
                        }
                    });
                }
            });



        },

        setFinish() {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            axios.put('cleanTask/' + t.currentTask.id, { 'is_finish': 1 }).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    t.currentTask.is_finish = 1;
                }
            });

        },

    },
    data() {
        return {
            loading: false,
            currentTask: null,
            todayDateStr: '',
            realtimeStr: '',
            list: {
                cleanArea: [],
                roomArea: [],
            },
        };
    },
    computed: {
    }
};
