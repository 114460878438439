

export default {


    created() {

        let t = this;

        t.loadData();

    },
    activated() {

        let t = this;

    },
    methods: {

        onPasswordChange(password) {
            let t = this;
            if (password == '8989') {
                t.signIned = true;
            }
        },
        loadData() {
            let t = this;

            let dictUnit = {};

            axios.get('https://hk.kyoran.jp/rp/v1.php?_a=getUnitsAndMembers').then(res => {
                if (res.data.status == 1) {
                    let units = res.data.options.units;
                    let members = res.data.options.members;

                    units.forEach(unit => {
                        dictUnit[unit.id] = unit;
                        unit.members = [];
                    });

                    let lastMember;
                    members.forEach(member => {
                        dictUnit[member.unit_id].members.push(member);
                        member.unit = dictUnit[member.unit_id];

                        if (localStorage['last_employee_id'] == member.id)
                            lastMember = member;
                    });
                    t.list.unit = units;

                    if (lastMember)
                        t.selectMeber(lastMember);
                }
            });

        },

        loadReportsByDate() {

            let t = this;
            let date = t.date;

            if (date && t.m)
                axios.get('https://hk.kyoran.jp/rp/v1.php?_a=loadReports&date=' + date + '&employee_id=' + t.m.id).then(res => {
                    if (res.data.status == 1) {
                        t.list.report = res.data.options.reports;
                        t.daily = res.data.options.daily_report;
                    }
                });

        },

        selectMeber(member) {
            let t = this;

            t.m = member;

            localStorage['last_employee_id'] = t.m.id;

            t.showMemberPopover = false;

            t.loadReportsByDate();
        },

        selectDate(date) {
            this.date = date.format('yyyy-MM-dd');
            this.showDatePopover = false;
            this.loadReportsByDate();
        },

        removeReport() {
            let t = this;

            A.safety('その従業員の日報を削除してもよろしいですか？').then(res => {
                if (res.isConfirmed) {
                    let f = {
                        date: t.date,
                        employee_id: t.m.id
                    };
                    axios.post('https://hk.kyoran.jp/rp/v1.php?_a=delReport', f).then(res => {
                        if (res.data.status == 1) {
                            t.daily = null;
                            t.list.report.length = 0;
                        }
                    });

                }
            });

        },

    },
    data() {
        return {

            signIned: false,

            m: null,

            list: {
                member: [],
                unit: [],
                report: []
            },

            showMemberPopover: false,
            showDatePopover: false,

            minDate: new Date('2023/10/15'),
            maxDate: new Date(),

            daily: null,
            date: new Date().format('yyyy-MM-dd')


        };
    },
};
