

export default {
    name:'pl_cleaner',
    created() {

        let t = this;

        document.body.classList.add('pc');

        t.loadData();

    },
    activated() {

        let t = this;

    },
    methods:{

        loadData(){

            let t = this;

            axios.get('employee').then(res => {
                if (res.data.status == 1) {
                    let arr = res.data.options.items;

                    t.list.employee = arr;
                }
            });
            
        },

        viewEmployeeAttendace(item){
            this.employee = item;
            this.showAttendance = true;
            this.loadMonthlyStats();
        },

        loadMonthlyStats(){

            let t = this;

            let dayIndexs = ['日', '月', '火', '水', '木', '金', '土'];

            t.dictWaitingTask = {};

            t.list.dateGrid.clear();
            
            axios.post('cleanTask/readMonthly/' + t.month+"?eid=" + t.employee.id+'&with=waiting').then(res => {
                if (res.data.status == 1) {

                    let dictDate = {};

                    let todayText = (new Date()).format('MM/dd');
                    let todayVal = new Date();

                    let startDate = new Date(res.data.options.start_date);
                    let endDate = new Date(res.data.options.end_date);

                    let i = 0;

                    while(true){
                        let curDate = new Date(res.data.options.start_date);
                        curDate.setDate(curDate.getDate() + i);
                        if(curDate > endDate)
                            break;
                        let date = curDate.format('MM/dd');
                        let dayIndex = dayIndexs[curDate.getDay()];
                        let item = {
                            date:date,
                            day_index:dayIndex,
                            week_index:curDate.getDay(),
                            tasks:[],
                            is_today: date == todayText,
                            is_past: curDate < todayVal
                        };
                        dictDate[date] = item;
                        t.list.dateGrid.push(item);
                        i++;
                    }

                    let arr = res.data.options.items;

                    arr.forEach(task=>{
                        let date = new Date(task.date).format('MM/dd');
                        let item = dictDate[date];
                        if(task.cleaner_id == t.employee.id)
                            item.tasks.push(task);
                        else{
                            if(!t.dictWaitingTask[date])
                                t.dictWaitingTask[date] = [];

                            t.dictWaitingTask[date].push(task);
                        }
                    });
                }
            });

        },

        minusMonth(){
            let t = this;
            let r = addMonthsToDate(new Date((new Date()).getFullYear(), t.month - 1,1),-1);
            t.month = r.getMonth() + 1;
            t.loadMonthlyStats();
        },

        plusMonth(){
            let t = this;
            let r = addMonthsToDate(new Date((new Date()).getFullYear(), t.month - 1,1),1);
            t.month = r.getMonth() + 1;
            t.loadMonthlyStats();
        },

        editDateGrid(item){
            
            if(item.is_past)
                return;

            let t = this;

            t.currentItem = item;
            t.selectedTask = null;
            t.showCleanTaskEditor = true;
            console.log(t.dictWaitingTask[item.date]);
            t.list.task = t.dictWaitingTask[item.date];
        },

        removeItem(item){

            let t = this;

            if (t.busying)
                return;
            
            t.busying = true;

            A.safety('Are you sure to delete this clean task?').then(res => {

                t.busying = false;
                
                if (res.isConfirmed) {

                    let f = {
                        id: item.order_id,
                        cleaner_id:null,
                    };

                    axios.post('order/setCleaner', f).then(res => {

                        t.busying = false;

                        if (res.data.status == 1) {

                            t.currentItem.tasks.remove(item);
                            t.$forceUpdate();
                        }
                    });

                }
            });
        },

        addTask(){
            let t = this;

            if (t.busying)
                return;
            
            t.busying = true;
            
            let item = t.selectedTask;

            t.currentItem.tasks.push(t.selectedTask);

            t.dictWaitingTask[t.currentItem.date].remove(t.selectedTask);

            t.selectedTask = null;

            let f = {
                        id: item.order_id,
                        cleaner_id: t.employee.id,
                        clean_status: 1,
                    };

            axios.post('order/setCleaner', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    item.cleaner_id = t.employee.id;
                    item.status = 1;
                    t.$forceUpdate();
                }
            });

        }

    },
    data() {
        return {
            dictWaitingTask:{},
            employee:null,
            showAttendance: false,
            //attendace
            attendanceExpanded:true,
            month: (new Date()).getMonth() + 1,
            list: {
                dateGrid:[],
                employee: [],
                task:[]
            },
            selectedTask:null,
            currentItem:null,
            showTaskPopover:false,
            showCleanTaskEditor:false
        };
    },
    computed: {
    }
};
