

export default {

    created() {

        let t = this;

        t.list.country = window['COUNTRY_LIST'];

        t.list.country.forEach(item => {
            item.icon = 'assets/img/flag/' + item.code + '.svg';
        });

        t.dict.country = listToDict(t.list.country, 'code');

        // t.f = {
        //     firstname: 'Exia',
        //     lastname: 'OO',
        //     address: 'Void Street Zero Cell',
        //     email: 'exia@qq.com',
        //     country: 'jp',
        //     tel: '010-9921-9292',
        //     password: 'exia11',
        //     business_ids: '1,2',
        //     business_str: '清掃スタッフ、ドライバー'
        // };

        t.termIsChecked = true;
    },
    activated() {

        let t = this;

    },
    methods: {

        onSelectCountry(item) {
            this.f.country = item.code;
        },

        onBusinessChange() {
            let t = this;

            let businessStr = [];
            let businessIds = [];
            t.list.business.forEach(item => {
                if (item.selected) {
                    businessStr.push(item.name);
                    businessIds.push(item.id);
                }
            });
            t.f.business_ids = businessIds.join(',');
            t.f.business_str = businessStr.join('、');
            if (t.f.business_ids != '') t.msg.business_ids = '';
        },

        signUp() {
            let t = this;
            let f = t.f;

            if (t.busying)
                return;

            mirror(t.msg_o, t.msg);

            let go = true;

            if (!f.firstname) {
                t.msg.firstname = 'Required';
                go = false;
            }

            if (!f.lastname) {
                t.msg.lastname = 'Required';
                go = false;
            }

            if (!f.username) {
                t.msg.username = 'Required';
                go = false;
            }

            if (!f.address) {
                t.msg.address = 'Required';
                go = false;
            }

            if (!f.email) {
                t.msg.email = 'Required';
                go = false;
            }

            if (!/^[a-zA-Z0-9\.]{1,}@[a-zA-Z0-9\.]{1,}[a-zA-Z0-9]$/.test(f.email)) {
                t.msg.email = 'Please enter a valid email address';
                go = false;
            }

            if (f.password.length < 6) {
                t.msg.password = 'Password length must be at least 6 digits';
                go = false;
            }

            if (!f.tel) {
                t.msg.tel = 'Required';
                go = false;
            }

            if (!f.business_ids) {
                t.msg.business_ids = 'Required';
                go = false;
            }

            if (f.country != 'jp' && !t.residenceCardIsUploaded) {
                A.err('Please upload your residence card.');
                go = false;
            }

            if (!t.termIsChecked) {
                t.msg.terms = 'Please accept the T&C';
                go = false;
            }

            if (!go)
                return;

            function _post() {

                t.busying = true;

                let data = getFields(f, ['firstname', 'lastname', 'address', 'email', 'password', 'tel', 'country', 'business_ids', 'rc_img_url','username']);

                axios.post('employee/signUp', data).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        A.show('Please remember your username and password, it will use to sign in.', 'You finished signup.', 'success').then(res => {
                            t.$router.replace('/signin');
                        });
                    }
                });

            }

            if (f.country != 'jp' && t.residenceCardIsUploaded) {
                let fd = new FormData();

                fd.append('image', t.rcImageBlob);

                t.busying = true;

                axios.post('employee/uploadRC', fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        f.rc_img_url = res.data.options.img_url;
                        _post();
                    }

                });

            }
            else {
                _post();
            }


        },

        closeCropper() {
            $('.cropper-item').hide();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 600,
                minWdith: 600,
                maxHeight: 1000,
                maxWdith: 1000,
                fillColor: '#fff',
            }).toBlob((blob) => {

                t.rcImageBlob = blob;

                t.residenceCardIsUploaded = true;

                t.closeCropper();
            });
        },

        uploadPicture() {

            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 4 / 3,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

    },
    data() {
        return {
            residenceCardIsUploaded: false,
            termIsChecked: false,
            showCountryPopover: false,
            showBusinessPopover: false,
            msg: {
                firstname: '',
                lastname: '',
                address: '',
                email: '',
                username:'',
                tel: '',
                password: '',
                business_ids: '',
                terms: '',
            },
            msg_o: {
                firstname: '',
                lastname: '',
                address: '',
                email: '',
                username:'',
                tel: '',
                password: '',
                business_ids: '',
                terms: '',
            },
            f: {
                firstname: '',
                lastname: '',
                address: '',
                email: '',
                username:'',
                country: 'jp',
                tel: '',
                password: '',
                business_ids: '',
                business_str: ''
            },

            list: {
                business: [
                    { id: 1, name: '清掃スタッフ' },
                    { id: 2, name: 'ドライバー' },
                ],
                country: []
            },
            dict: {
                country: {}
            },
            
            rcImageBlob: null,

            cropper: null,


        };
    },
};
