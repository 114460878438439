

export default {
    name: "PickSupTasks",
    props: {
    },
    created() {

        let t = this;

        axios.get('room').then(res => {
            if (res.data.status == 1) {
                let rooms = res.data.options.items;

                t.initRooms(rooms);

                t.roomDict = listToDict(rooms);

                t.list.room = rooms;

                axios.get('group').then(res => {
                    if (res.data.status == 1) {
                        let arr = res.data.options.items;
                        arr.forEach(group => {
                            group.rooms = [];
                            let roomIds = group.room_ids.split(',');
                            roomIds.forEach(roomId => {
                                group.rooms.push(t.roomDict[roomId]);
                            });
                        });

                        t.list.group = arr;
                    }
                });

                t.onSelectStartDate(t.getMondayOfThisWeek());
            }
        });

        t.initSocket();

        t.curEmployee = t.U;

    },

    beforeDestroy() {
        if (window.sok) {
            try {
                window.sok.close(1000, "Closing connection");
            } catch (ex) { }
        }
    },

    deactivated() {
    },

    methods: {


        prevWeek() {

            let t = this;

            let sd = new Date(t.list.week[0].start_date);

            sd.setDate(sd.getDate() - t.weeks * 7);

            t.curDay = {};

            t.onSelectStartDate(sd);
        },

        nextWeek() {

            let t = this;

            let sd = new Date(t.list.week[0].start_date);

            sd.setDate(sd.getDate() + t.weeks * 7);

            t.curDay = {};

            t.onSelectStartDate(sd);
        },

        onSelectStartDate(d) {
            let t = this;

            t.startDate = d;

            t.weeks = 5;

            t.endDate = new Date(
                t.startDate.getFullYear(),
                t.startDate.getMonth(),
                t.startDate.getDate() + t.weeks * 7
            );

            let arr = [];

            for (let i = 0; i < t.weeks; i++) {

                let sd = new Date(d.valueOf());
                sd.setDate(sd.getDate() + i * 7);

                let ed = new Date(sd.valueOf());
                ed.setDate(ed.getDate() + 6);

                arr.push({
                    start_date: sd.format('yyyy-MM-dd'),
                    end_date: ed.format('yyyy-MM-dd'),
                    passed: t.today.valueOf() > ed.valueOf(),
                    count_left: 0,
                });

            }

            t.list.week = arr;

            let p = {
                start_date: t.list.week[0].start_date,
                end_date: t.list.week[t.list.week.length - 1].end_date,
            };

            axios.get('supTask/readStatsByDate', { params: p }).then(res => {
                if (res.data.status == 1) {

                    let dict = res.data.options.dict;

                    t.list.week.forEach(week => {
                        let count_left = 0;
                        for (let i = 0; i < 7; i++) {
                            let d = new Date(week.start_date);
                            d.setDate(d.getDate() + i);
                            let dtext = d.format('yyyy-MM-dd');
                            if (dict[dtext]) {
                                count_left += Number(dict[dtext]);
                            }
                        }
                        week.count_left = count_left;
                    });

                    t.selectWeek(t.list.week[0]);

                }
            });

        },
        initRooms(rooms) {
            rooms.forEach(room => {
                room.selected = false;
                room.disabled = false;
                room.status = 0;
                room.alert = 0;
                room.confirmed = 0;
                room.sup_task_id = null;
                room.employee_id = null;
                room.date = null;
                room.frozen = false;
            });
        },

        selectWeek(week, loadDay) {

            let t = this;

            t.initRooms(t.list.room);

            t.curWeek = week;

            let todayText = (new Date()).format('yyyy-MM-dd');

            let p = {
                date: [week.start_date, week.end_date].join(',')
            };

            axios.get('supTask', { params: p }).then(res => {
                if (res.data.status == 1) {

                    let items = res.data.options.items;

                    let dict = {};

                    items.forEach(item => {
                        t.roomDict[item.room_id].disabled = true;
                        t.roomDict[item.room_id].employee_id = item.employee_id;
                        t.roomDict[item.room_id].status = item.status;
                        t.roomDict[item.room_id].alert = item.alert;
                        t.roomDict[item.room_id].confirmed = item.confirmed;
                        t.roomDict[item.room_id].sup_task_id = item.sup_task_id;
                        t.roomDict[item.room_id].date = item.date;

                        if (!dict[item.date])
                            dict[item.date] = {};
                        if (!dict[item.date][item.employee_id])
                            dict[item.date][item.employee_id] = [];
                        dict[item.date][item.employee_id].push(item);
                    });

                    let eid = t.curEmployee.id;

                    t.list.day = [];
                    for (let i = 0; i < 7; i++) {
                        let d = new Date(week.start_date);
                        d.setDate(d.getDate() + i);
                        let dtext = d.format('yyyy-MM-dd');

                        let day = {
                            date: dtext,
                            rooms: [],
                            isToday: dtext == todayText,
                            day_suffix: ["日", "月", "火", "水", "木", "金", "土"][d.getDay()]
                        }
                        if (dict[dtext] && dict[dtext][eid]) {
                            dict[dtext][eid].forEach(supTask => {
                                day.rooms.push(t.roomDict[supTask.room_id]);
                                t.roomDict[supTask.room_id]._day = day;
                                t.roomDict[supTask.room_id].sup_task_id = supTask.id;
                                if (supTask.status != 3)
                                    day.count_unfinish++;
                            });
                        }

                        t.list.day.push(day);
                    }

                    t.selectDay(loadDay || t.list.day[0])

                    t.$forceUpdate();
                }
            });

        },

        selectDay(day) {
            let t = this;

            let p = {
                start_date: day.date,
                end_date: day.date,
            };

            axios.get('cleanTask/readCleanDates', { params: p }).then(res => {
                if (res.data.status == 1) {

                    let cleanDateDict = res.data.options.dict;

                    t.list.room.forEach(room => {
                        room.selected = false;
                    });

                    t.curDay = day;

                    let dict = listToDict(day.rooms);

                    t.list.group.forEach(group => {
                        group.rooms.forEach(room => {
                            if (dict[room.id]) {
                                room.selected = true;
                            }
                            room.frozen = false;
                            if (cleanDateDict[day.date]) {
                                if (cleanDateDict[day.date].indexOf(room.id) < 0)
                                    room.frozen = true;
                            }
                        });
                    });

                }
            });

        },

        selectRoom(room) {

            let t = this;

            if (t.busying)
                return;

            if (room.frozen)
                return;

            t.curRoom = room;

            if (room.alert == 1) {
                t.clickRoom(room);
                return;
            }
            if (room.disabled && room.confirmed == 1) {
                if (room.employee_id && (room.employee_id == t.U.id || t.U.role == 1) && room.confirmed == 1)
                    t.showCancelAlert4Driver = true;
                return;
            }

            if (t.curDay.rooms.indexOf(room) > -1) {

                t.busying = true;

                axios.delete('supTask/' + room.sup_task_id).then(res => {
                    if (res.data.status == 1) {

                        room.disabled = false;
                        room.selected = false;
                        room.sup_task_id = null;
                        room.employee_id = null;
                        room.status = 0;
                        room.alert = 0;
                        room.date = null;

                        let day = t.curDay;

                        room._day = null;

                        day.rooms.remove(room);

                        t.curRoom = null;

                        t.sendMessage({
                            type: 'unlock',
                            date: t.curWeek.start_date,
                            task_date: t.curDay.date,
                            room_ids: [room.id],
                        });

                        t.$forceUpdate();
                    }
                    t.busying = false;
                });

            }
            else {

                if (t.curDay.rooms.length >= 16) {
                    return;
                }

                let f = {
                    room_id: room.id,
                    employee_id: t.curEmployee.id,
                    date: t.curDay.date,
                    status: 1,
                    confirmed: 0
                };

                t.busying = true;

                axios.post('supTask', f).then(res => {
                    if (res.data.status == 1) {
                        t.curDay.rooms.push(room);
                        room.selected = true;
                        room.status = res.data.options.status;
                        room.sup_task_id = res.data.options.id;
                        room.employee_id = res.data.options.employee_id;
                        room.date = res.data.options.date;
                        room._day = t.curDay;
                        if (t.curEmployee && room.employee_id == t.curEmployee.id)
                            room.disabled = true;
                        t.sendMessage({
                            type: 'lock',
                            date: t.curWeek.start_date,
                            task_date: t.curDay.date,
                            time: (new Date()).format('yyyy-MM-dd hh:mm:ss'),
                            room_id: room.id,
                            employee_id: t.curEmployee.id,
                            sup_task_id: room.sup_task_id,
                            status: room.status,
                        });
                        t.$forceUpdate();
                    }
                    else if (res.data.status == 2) {
                        A.err('この部屋は今週割り当てられた。');
                    }
                    t.busying = false;
                });

            }

            t.$forceUpdate();
        },

        removeSupTask() {

            let t = this;

            let room = t.curRoom;

            axios.delete('supTask/' + room.sup_task_id).then(res => {
                if (res.data.status == 1) {
                    t.sendMessage({
                        type: 'unlock',
                        date: t.curDay.date,
                        room_ids: [room.id],
                    });

                    room.disabled = false;
                    room.selected = false;
                    room.sup_task_id = null;
                    room.employee_id = null;
                    room.status = 0;
                    room.alert = 0;
                    room.date = null;

                    let day = room._day;

                    room._day = null;

                    day.rooms.remove(room);

                    t.curRoom = null;
                }
            });

        },

        save() {

            let t = this;

            let supTaskIds = [];

            t.curDay.rooms.forEach(room => {

                if (room.confirmed == 0) {
                    supTaskIds.push(room.sup_task_id);
                }

            });

            if (supTaskIds.length > 0) {
                axios.post('supTask/batchConfrim', { sup_task_ids: supTaskIds.join(',') }).then(res => {
                    if (res.data.status == 1) {
                        t.selectWeek(t.curWeek, t.curDay);
                        A.toast('Saved.');
                    }
                });
            }

        },

        receiveMessage(message) {
            let t = this;
            let data = JSON.parse(message.data);
            if (data.event == 'room_sync') {
                let date = data.extras.date;
                if (date == t.curWeek.start_date) {
                    if (data.extras.type == 'lock') {
                        t.list.room.forEach(room => {
                            if (room.id == data.extras.room_id) {
                                room.disabled = true;
                                room.employee_id = data.extras.employee_id;
                                room.status = data.extras.status;
                                room.sup_task_id = data.extras.sup_task_id;
                                room.date = data.extras.task_date;

                                if (data.extras.employee_id == t.curEmployee.id) {

                                    t.list.day.forEach(day => {

                                        if (day.date == data.extras.task_date) {
                                            room._day = day;
                                            day.rooms.push(room);
                                        }

                                    });
                                }

                            }
                        });

                    }
                    else if (data.extras.type == 'unlock') {
                        t.list.room.forEach(room => {
                            if (data.extras.room_ids.indexOf(room.id) > -1) {
                                room.disabled = false;
                                room.selected = false;
                                room.sup_task_id = null;
                                room.employee_id = null;
                                room.status = 0;
                                room.alert = 0;
                                room.date = null;
                                if (room._day) {
                                    room._day.rooms.remove(room);
                                }
                                t.$forceUpdate();
                            }
                        });
                    }
                }
            }
        },

        clickRoom(room) {
            let t = this;
            if (room.alert == 1) {
                t.curRoom = room;
                t.showChangedAlert = true;
            }
        },


    },
    data() {
        return {
            busying: false,
            showDisabledAlert: false,
            showCancelAlert4Driver: false,
            showChangedAlert: false,
            curWeek: null,
            curRoom: null,
            startDate: null,
            endDate: null,
            today: Date.today(),
            roomDict: {},
            weeks: 5,
            list: {
                week: [],
                room: [],
                group: [],
                day: [],
            },
            curDay: {},
            curEmployee: {}
        };
    },
};

