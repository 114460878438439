

export default {
    name: "SortPicker",
    props: {
        items: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: false
        },
        index: {
            type: String,
            required: false
        },
    },
    mounted() { },
    created() {
        let t = this;

        t.items.forEach(item => {
            item._active = false;
        });

        t.items2 = [];

        t.items2.append(t.items.sort((a, b) => {
            return a.id > b.id;
        }));


        let dict = listToDict(t.items2);
        
        if (t.name) {

            if (localStorage[t.name]) {
                let selection = [];
                selection = localStorage[t.name].split(",");
                selection.forEach(id => {
                    if (dict[id]) {
                        t.selection.push(dict[id]);
                        t.items2.remove(dict[id]);
                    }
                });
            }
        }

        if (t.index) {
            let selection = t.index.split(',');
            selection.forEach(id => {
                if (dict[id]) {
                    t.selection.push(dict[id]);
                    t.items2.remove(dict[id]);
                }
            });

        }
        else if (t.items2[0].selected != undefined) {

            let toRemove = [];

            t.items2.forEach(item => {

                if (item.selected) {
                    t.selection.push(item);
                    toRemove.push(item);
                }

            });

            toRemove.forEach(item => {

                t.items2.remove(item);

            });
        }

    },
    activated: function (el) {
    },
    deactivated() {
    },
    methods: {
        setSelect() {
            let t = this;
            let arr = [];
            t.items2.forEach(item => {
                if (item._active) {
                    item._active = false;
                    t.selection.push(item);
                    arr.push(item);
                }
            });

            t.selection.forEach(item => {
                t.items2.remove(item);
            });

            t.$forceUpdate();
        },
        selectAll() {
            let t = this;
            t.items2.forEach(item => {
                t.selection.push(item);
            });
            t.items2 = [];

            t.$forceUpdate();
        },
        setCancel() {
            let t = this;
            if (t.last) {
                t.last._active = false;
                t.selection.remove(t.last);
                t.items2.unshift(t.last);
                t.last = null;
            }
        },
        cancelAll() {
            let t = this;
            t.last = null;
            t.items2.append(t.selection);
            t.items2 = t.items2.sort((a, b) => {
                return a.id > b.id;
            });
            t.selection.clear();
            t.$forceUpdate();
        },
        selectRight(item) {
            let t = this;
            if (t.last == item && item._active) {
                t.last._active = false;
                t.$forceUpdate();
                return;
            }
            if (t.last) {
                t.last._active = false;
            }
            t.last = item;
            item._active = true;
            t.$forceUpdate();
        },
        moveUp() {
            let t = this;
            if (t.last) {
                let idx = t.selection.indexOf(t.last);
                if (idx > 0) {
                    let source = t.selection[idx - 1];
                    t.selection[idx - 1] = t.last;
                    t.selection[idx] = source;
                }
                t.$forceUpdate();
            }
        },
        moveDown() {
            let t = this;
            if (t.last) {
                let idx = t.selection.indexOf(t.last);
                if (idx < (t.selection.length - 1)) {
                    let source = t.selection[idx + 1];
                    t.selection[idx + 1] = t.last;
                    t.selection[idx] = source;
                }
                t.$forceUpdate();
            }

        },
    },
    data() {
        return {
            last: null,
            items2: [],
            selection: [],
            keywords: ''
        };
    },
    computed: {
        filteredItems() {
            let t = this;
            return t.items2.filter((item) => {
                if (item.name != undefined) {
                    if (item.name.indexOf(t.keywords) > -1) return true;
                }
                else {
                    if ((item.lastname + item.firstname).indexOf(t.keywords) > -1) return true;
                }
                return false;
            });
        },
    }
};
