
export default {
  created() {
    let t = this;

    let y = 2024 - 1930;
    for (let i = 0; i < y; i++) {
      t.list.year.push(i + 1930);
    }
    for (let i = 0; i < 12; i++) {
      t.list.month.push(i + 1);
    }
    for (let i = 0; i < 31; i++) {
      t.list.day.push(i + 1);
    }
    for (let i = 0; i <= 20; i++) {
      t.list.owned.push(i);
    }
    t.list.owned.push("20+");

    if (localStorage["business_form_1009"]) {
      let _f = JSON.parse(localStorage["business_form_1009"]);
      mirror(_f, t.f);
    }

    t.f.bid = localStorage["bid_1009"] || localStorage["bid"] || "999";
    if (localStorage["lineProfile"]) {
      let lineProfile = JSON.parse(localStorage["lineProfile"]);
      if (lineProfile) t.f.line_user_id = lineProfile.userId;
    }
  },
  activated() {
    let t = this;
  },
  methods: {
    submit() {
      let t = this;
      let f = t.f;

      t.errMsg = "";

      if (!f.lastname) {
        t.errMsg = "氏をご入力ください。";
        return;
      }
      if (!f.firstname) {
        t.errMsg = "名をご入力ください。";
        return;
      }
      if (!f.year) {
        t.errMsg = "年をご入力ください。";
        return;
      }
      if (!f.month) {
        t.errMsg = "月をご入力ください。";
        return;
      }
      if (!f.day) {
        t.errMsg = "日をご入力ください。";
        return;
      }
      if (
        !f.email ||
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,6})$/.test(
          f.email
        ) == false
      ) {
        t.errMsg = "メールアドレスを半角英数字でご入力ください";
        return;
      }
      if (!f.tel_prefix || !f.tel || !f.tel_suffix) {
        t.errMsg = "電話番号をご入力ください";
        return;
      }

      if (t.busying) return;

      t.busying = true;

      f.activity = '1009';
      f.status = 3;

      axios.post("businessForm/saveFull", f).then((res) => {
        t.busying = false;

        if (res.data.status == 1) {
          t.f.id = res.data.options.item.id;
          localStorage["business_form_1009"] = JSON.stringify(t.f);
        }
      });
    },
    updateDay() {
      let t = this;
      let d = 0;
      t.list.day.clear();
      if (t.f.year && t.f.month) {
        if (t.f.month == 2)
          if (Number(t.f.year) % 4 == 0) d = 29;
          else d = 28;
        else if ([1, 3, 5, 7, 8, 10, 12].indexOf(Number(t.f.month))) d = 31;
        else d = 30;

        for (let i = 0; i < d; i++) {
          t.list.day.push(i + 1);
        }
        if (Number(t.f.day) > d) t.f.day = "1";
      }
    },
    selectJob(v) {
      this.f.job = v;
      this.showJobPicker = false;
    },
    selectSector(v) {
      this.f.sector = v;
      this.showSectorPicker = false;
    },
    selectNum1(v) {
      this.f.owned_building = v;
      this.showNum1Picker = false;
    },
    selectNum2(v) {
      this.f.owned_house = v;
      this.showNum2Picker = false;
    },
    selectNum3(v) {
      this.f.owned_department = v;
      this.showNum3Picker = false;
    },
    fillByPostcode(){
      let t = this;
      
      if(t.f.postcode && t.f.postcode.trim().length >= 5){
        axios.post('businessForm/getLocationByPostcode/' + t.f.postcode).then(res => {
            if (res.data.status == 1) {
                console.log(res.data);
                t.f.province = res.data.options.item.province ;
                t.f.city = res.data.options.item.city;
                t.f.town = res.data.options.item.town;
            }
        });
        
      }

    }
  },
  data() {
    return {
      showJobPicker: false,
      showSectorPicker: false,
      showNum1Picker: false,
      showNum2Picker: false,
      showNum3Picker: false,
      errMsg: "",
      list: {
        owned: [],
        year: [],
        month: [],
        day: [],
        city: [],
        sector: [
          "農林水産業",
          "鉱業、採石業、砂利採取業",
          "製造業",
          "電気・ガス業",
          "水道業、廃棄物処理業",
          "建設業",
          "卸売業・小売業",
          "運輸業、倉庫業",
          "宿泊業、飲食サービス業",
          "情報通信業",
          "金融業、保険業",
          "不動産業",
          "専門、科学技術サービス業",
          "生活関連サービス業、その他サービス業",
          "公共行政、国防、社会保障",
          "教育業",
          "医療、福祉",
          "芸術、娯楽、レクリエーション業",
          "その他のサービス業",
          "职业"
        ],
        job: [
          "会社員",
          "役員",
          "創業者、会長",
          "自営業主",
          "主婦",
          "公務員",
          "不動産投資家",
          "学生",
          "アルバイト、パート",
          "その他"
        ],
      },
      // f: {
      //   id:0,
      //   line_user_id: "test",
      //   bid: 2001,
      //   status: 1,
      //   firstname: "雷欧",
      //   lastname: "艾克西亚",
      //   firstname_en: "Leo",
      //   lastname_en: "Exia",
      //   year: "1994",
      //   month: "8",
      //   day: "12",
      //   email: "test@qq.com",
      //   tel_prefix: "881",
      //   tel: "2912",
      //   tel_suffix: "2222",
      //   postcode: "640820",
      //   province:"北海道",
      //   city: "札幌市中央区",
      //   town: "大通西（２０〜２８丁目）",
      //   address: "测试地址",
      //   job: "公司職員",
      //   sector: "不動產業",
      //   income_payroll: "1000",
      //   income_external: "800",
      //   capital: "20000",
      //   owned_building: "1",
      //   owned_house: "2",
      //   owned_department: "10",
      //   agreed_contact: "1",
      // },
      f: {
        line_user_id: "",
        bid: 0,
        status: 1,
        firstname: "",
        lastname: "",
        firstname_en: "",
        lastname_en: "",
        year: "",
        month: "",
        day: "",
        email: "",
        tel_prefix: "",
        tel: "",
        id: 0,
        postcode: "",
        province:"",
        city: "",
        town: "",
        address: "",
        job: "",
        sector: "",
        income_payroll: "",
        income_external: "",
        capital: "",
        owned_building: "0",
        owned_house: "0",
        owned_department: "0",
        agreed_contact: "1",
      },
    };
  },
};
